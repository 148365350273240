import {
  GET_FORM_FAILURE,
  GET_FORM_REQUEST,
  GET_FORM_SUCCESS,
  GET_MODULES_FAILURE,
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULE_FAILURE,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_LOCALES_REQUEST,
  GET_LOCALES_SUCCESS,
  GET_LOCALES_FAILURE,
  SET_INSPECTION_FORM_DATA,
  SET_INSPECTION_FORM_ERRORS,
} from "./types";

export const getModulesRequest = () => ({
  type: GET_MODULES_REQUEST,
});
export const getModulesSuccess = (data) => ({
  type: GET_MODULES_SUCCESS,
  data,
});
export const getModulesFailure = (error) => ({
  type: GET_MODULES_FAILURE,
  error,
});

export const getModuleRequest = (id) => ({
  type: GET_MODULE_REQUEST,
  id,
});
export const getModuleSuccess = (data) => ({
  type: GET_MODULE_SUCCESS,
  data,
});
export const getModuleFailure = (error) => ({
  type: GET_MODULE_FAILURE,
  error,
});

export const getLocalesRequest = () => ({
  type: GET_LOCALES_REQUEST,
});

export const getLocalesSuccess = (data) => ({
  type: GET_LOCALES_SUCCESS,
  data,
});

export const getLocalesFailure = (error) => ({
  type: GET_LOCALES_FAILURE,
  error,
});

export const getFormRequest = (
  moduleId,
  formId,
  isFromCloning,
  isFromAddNewSections
) => ({
  type: GET_FORM_REQUEST,
  moduleId,
  formId,
  isFromCloning,
  isFromAddNewSections,
});
export const getFormSuccess = (data) => ({
  type: GET_FORM_SUCCESS,
  data,
});
export const getFormFailure = (error) => ({
  type: GET_FORM_FAILURE,
  error,
});

export const setInspectionFormData = (data) => ({
  type: SET_INSPECTION_FORM_DATA,
  data,
});
export const setInspectionFormErrors = (errors) => ({
  type: SET_INSPECTION_FORM_ERRORS,
  errors,
});
