import axios from "axios";
import axiosRetry from "axios-retry";
import { AuthService } from "@myosh/myosh-login";
// import Utils from "../Utils/Utility";

const authService = new AuthService();

const wrapAxiosClient = (client) => {
  axiosRetry(client, {
    retries: 3,
    retryCondition: (error) => {
      if(error && !error.response) {
        return false;
      }

      if (error.response.status === 401) {
        let valid = true;
        authService
          .signinSilent()
          .then((userData) => {
            if (userData) {
              localStorage.setItem("idToken", userData.id_token);
              valid = true;
            } else {
              authService.logout();
              valid = false;
            }
          })
          .catch(() => {
            authService.logout();
            valid = false;
          });

        return valid;
      } else {
        return false;
      }
    },
    retryDelay: (retryCount) => {
      return retryCount * 2000;
    },
  });
};

export const request = (options, config) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_V4,
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "myosh-schema": localStorage.getItem("schema"),
    },
  });

  client.interceptors.request.use((requestConfig) => {
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ` + localStorage.getItem("idToken"),
      "Content-Type": "application/json"
    };

    return requestConfig;
  });

  wrapAxiosClient(client);

  const onSuccess = (response) => response;
  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
