import {
  CLONE_FORM_FAILURE,
  CLONE_FORM_REQUEST,
  CLONE_FORM_SUCCESS,
  UPDATE_MODULE_FORM_FAILURE,
  UPDATE_MODULE_FORM_REQUEST,
  UPDATE_MODULE_FORM_SUCCESS,
} from "./types";

export const cloneFormRequest = (data) => ({
  type: CLONE_FORM_REQUEST,
  data,
});
export const cloneFormSuccess = (id) => ({
  type: CLONE_FORM_SUCCESS,
  id,
});
export const cloneFormFailure = (error) => ({
  type: CLONE_FORM_FAILURE,
  error,
});

export const updateModuleFormRequest = (data) => ({
  type: UPDATE_MODULE_FORM_REQUEST,
  data,
});

export const updateModuleFormSuccess = (id, addingNewSections) => ({
  type: UPDATE_MODULE_FORM_SUCCESS,
  id,
  addingNewSections,
});

export const updateModuleFormFailure = (error, addingNewSections) => ({
  type: UPDATE_MODULE_FORM_FAILURE,
  error,
  addingNewSections,
});
