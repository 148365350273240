import { combineReducers } from "redux";
// import { connectRouter } from "connected-react-router";
import modules from "./modules/reducers";
import files from "./files/reducers";
import creation from "./creation/reducers";

const rootReducer = combineReducers({
  modules,
  files,
  creation,
});

export default rootReducer;
