import { AppBar, Toolbar } from "@material-ui/core";
import MyoshLogo from "../assets/images/myosh-logo.png";

export default function Header() {
  return (
    <AppBar position="absolute" color="default">
      <Toolbar>
        <img src={MyoshLogo} className="myosh-logo" alt="myosh-logo"></img>
      </Toolbar>
    </AppBar>
  );
}
