import {useState, useEffect} from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import "./App.css";
import HorizontalLinearStepper from "./pages/Stepper/Stepper";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import querystring from 'query-string';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Login, LogoutCallback, LoginCallback } from "@myosh/myosh-login";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#398FBD" },
  },
});

function OverrideCSS({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

const App = () => {
  const location = useLocation();
  const { push } = useHistory();
  const [username, setUsername] = useState(null);

  const onLoginSuccess = (results) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('refreshToken', results.refreshToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    push("/");
  };

  useEffect(() => {
    if(location && location.search) {
      const parsedQuery = querystring.parse(location.search);
      if(parsedQuery && Object.keys(parsedQuery).length > 0) {
        if(parsedQuery.userName) {
          setUsername(parsedQuery.userName);
        }

        if(parsedQuery.username) {
          setUsername(parsedQuery.username);
        }
      }
    }
  }, [])


  return (
    <OverrideCSS>
      <div className="App">
        <Switch>
          <Route path="/auth/cb/logout">
            <LogoutCallback />
          </Route>
          <Route path="/auth/cb/silent">
            <div>Silent renewal callback</div>
          </Route>
          <Route path="/auth/cb">
            <LoginCallback />
          </Route>
          <Route path="/login">
            <Login onLoginSuccess={onLoginSuccess} username={username}/>
          </Route>

          <PrivateRoute path="/">
            <Header />
            <HorizontalLinearStepper></HorizontalLinearStepper>
          </PrivateRoute>
        </Switch>
      </div>
    </OverrideCSS>
  );
};

export default App;
