import { Typography, Link } from "@material-ui/core";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  developedBy: {
    id: "Login.developedBy",
    defaultMessage: "Developed By",
  },
});

function Copyright({ intl: { formatMessage } }) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {formatMessage(messages.developedBy)} ©
      <Link color="inherit" href="https://continuumworks.com">
        Continuum Works{" "}
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default injectIntl(Copyright);
