import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUser } from "@myosh/myosh-login";

export default function PrivateRoute({ children, ...rest }) {
  const { state } = useUser();
  const isAuthenticated = state.auth;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>{typeof children !== 'function' ? children : children(props)}</>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
