export class FileEnums {
  static FILE_HEADERS_BYID = {
    Section: "Section",
    Questions: "Questions",
    Weighting: "Weighting",
    Comments: "Comments",
    LinkedRecords: "Linked records",
    Score1: "Score 1",
    Score_1: "Score -1",
    Score0: "Score 0",
    Score0_1: "Score 0_1",
    Score0_2: "Score 0_2",
  };

  static REQUIRED_FILE_HEADERS = {
    Section: "Section",
    Questions: "Questions",
    Weighting: "Weighting",
    Comments: "Comments",
    Score1: "Score 1",
    LinkedRecords: "Linked records",
  };

  static FILE_HEADERS = [
    "Section",
    "Questions",
    "Weighting",
    "Comments",
    "Linked records",
    "Score 1",
    "Score -1",
    "Score 0",
    "Score 0",
    "Score 0",
  ];

  static COMMENTS_VALUES = {
    yes: "yes",
    no: "no",
  };

  static LINKED_RECORDS_VALUES = {
    hazards: "hazards",
    actions: "actions",
    hazard: "hazard",
    action: "action",
    none: "none",
  };
}
