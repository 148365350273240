import { StepLabel, withStyles } from "@material-ui/core";

const StepLabelWithStyles = withStyles((theme) => ({
  label: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  active: {
    textShadow: "0px 0.2px 0.6px black",
  },
}))(StepLabel);

export default StepLabelWithStyles;
