export const getCloneFormLoading = (state) => state.creation.cloneFormLoading;
export const getCloneFormError = (state) => state.creation.cloneFormError;
export const getCloneFormSuccess = (state) => state.creation.cloneFormSuccess;

export const getUpdateModuleFormLoading = (state) =>
  state.creation.updateModuleFormLoading;
export const getUpdateModuleFormError = (state) =>
  state.creation.updateModuleFormError;
export const getUpdateModuleFormSuccess = (state) =>
  state.creation.updateModuleFormSuccess;

export const getCreationLoading = (state) => state.creation.creationLoading;
export const getCreationError = (state) => state.creation.creationError;

export const getCreatedFormId = (state) => state.creation.createdFormId;
export const getUpdatedFormId = (state) => state.creation.updatedFormId;

export const getUpdateModuleFormGrandScoreError = (state) =>
  state.creation.updateModuleFormGrandScoreError;
export const getUpdateModuleFormGrandScoreSuccess = (state) =>
  state.creation.updateModuleFormGrandScoreSuccess;

export const getUpdatedFormError = (state) => state.creation.getFormError;
