import { put, call, takeLatest } from "redux-saga/effects";
import { api } from "../api";
import {
  cloneFormFailure,
  cloneFormSuccess,
  updateModuleFormFailure,
  updateModuleFormSuccess,
} from "./actions";
import { CLONE_FORM_REQUEST, UPDATE_MODULE_FORM_REQUEST } from "./types";
import { defineMessages } from "react-intl";
import Utils from "../../Utils/Utility";
import { getFormRequest } from "../modules/actions";

const intlStrings = defineMessages({
  serverError: {
    id: "serverError",
    defaultMessage: "Server responded with a status of ",
  },
  unknownServerError: {
    id: "unknownServerError",
    defaultMessage: "The server is not available at this time",
  },
});

function* cloneForm(action) {
  try {
    const response = yield call(
      api.creation.cloneForm,
      action.data.moduleId,
      action.data.data
    );
    yield put(cloneFormSuccess(response.data.result));
    yield put(getFormRequest(action.data.moduleId, response.data.result, true));
  } catch (error) {
    const intl = Utils.retrieveIntl();
    yield put(
      cloneFormFailure(
        error && error.data
          ? error.data.message
            ? error.data.message
            : error.data.validation.errors && error.data.validation.errors[0]
            ? error.data.validation.errors[0]
            : error.status
            ? intl.formatMessage(intlStrings.serverError) + error.status
            : intl.formatMessage(intlStrings.unknownServerError)
          : intl.formatMessage(intlStrings.unknownServerError)
      )
    );
  }
}

function* updateModuleForm(action) {
  try {
    yield call(
      api.creation.updateModuleForm,
      action.data.moduleId,
      action.data.formId,
      action.data.data
    );
    yield put(
      updateModuleFormSuccess(action.data.formId, action.data.addingNewSections)
    );

    if (action.data.enableScore) {
      yield put(
        getFormRequest(
          action.data.moduleId,
          action.data.formId,
          // is from cloning
          false,
          // Is from adding new sections of form
          action.data.addingNewSections
          // Is from adding grand score
          // action.data.grandScoreUpdate
        )
      );
    }
  } catch (error) {
    const intl = Utils.retrieveIntl();

    try {
      yield call(api.creation.deleteModuleForm, action.data.formId);
    } catch (err) {}

    yield put(
      updateModuleFormFailure(
        error && error.data
          ? error.data.message
            ? error.data.message
            : error.data.validation.errors && error.data.validation.errors[0]
            ? error.data.validation.errors[0]
            : error.status
            ? intl.formatMessage(intlStrings.serverError) + error.status
            : intl.formatMessage(intlStrings.unknownServerError)
          : intl.formatMessage(intlStrings.unknownServerError),
        action.data.addingNewSections
      )
    );
  }
}

export default function* modulesSaga() {
  yield takeLatest(CLONE_FORM_REQUEST, cloneForm);
  yield takeLatest(UPDATE_MODULE_FORM_REQUEST, updateModuleForm);
}
