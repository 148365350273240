import { useEffect, useState } from "react";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import { AuthService, useUser } from "@myosh/myosh-login";

const authService = new AuthService();

export default function UsernameGuard(props) {
  const location = useLocation();

  const userPayload = useUser();

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);

      if (
        parsedQuery &&
        (parsedQuery.userName || parsedQuery.username) &&
        userPayload &&
        userPayload.state.user
      ) {
        const incomingUsername = parsedQuery.userName || parsedQuery.username;
        const currentUsername = userPayload.state.user.prefered_username;

        if (
          incomingUsername &&
          currentUsername &&
          incomingUsername.toLowerCase() !==
            currentUsername.toLowerCase()
        ) {
          authService.logout();
        }
      }
    }
  }, [location.search]);

  return props.children;
}
