import {
  CLEAR_REDUX_STATE,
  SET_FORM_DATA,
  SET_RAW_FILE_DATA_FAILURE,
  SET_RAW_FILE_DATA_SUCCESS,
  SET_VALIDATED_FILE_DATA_SUCCESS,
} from "./types";

export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  data,
});
export const setRawFileDataSuccess = (data) => ({
  type: SET_RAW_FILE_DATA_SUCCESS,
  data,
});
export const setRawFileDataFailure = (error) => ({
  type: SET_RAW_FILE_DATA_FAILURE,
  error,
});
export const setValidatedFileDataSuccess = (data) => ({
  type: SET_VALIDATED_FILE_DATA_SUCCESS,
  data,
});
export const clearReduxState = () => ({
  type: CLEAR_REDUX_STATE,
});
