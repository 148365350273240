import { request } from "../request";

export const creation = {
  cloneForm: (moduleId, data) =>
    request({
      url: `/modules/${moduleId}/forms/clone`,
      method: "POST",
      data,
    }),
  updateModuleForm: (moduleId, formId, data) => {
    return request({
      url: `/modules/${moduleId}/forms/${formId}`,
      method: "PUT",
      data,
    })
  },
  deleteModuleForm: (formId, data) => {
    return request({
      url: `/forms/${formId}`,
      method: "DELETE",
      data
    })
  }
};
