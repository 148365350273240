import {
  SET_RAW_FILE_DATA_SUCCESS,
  SET_FORM_DATA,
  SET_RAW_FILE_DATA_FAILURE,
  SET_VALIDATED_FILE_DATA_SUCCESS,
  CLEAR_REDUX_STATE,
} from "./types";

const initialState = {
  file: null,
  filename: null,
  fileHeaders: null,
  rawFileData: null,
  rawFileDataError: null,

  validatedFileData: [],
  validatedFileDataErrors: null,
  validatedFileHeaderErrors: null,
};

export default function files(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
      };
    case SET_RAW_FILE_DATA_SUCCESS:
      return {
        ...state,
        file: action.data.file,
        filename: action.data.filename,
        fileHeaders: action.data.headers,
        rawFileData: action.data.fileRows,
        rawFileDataError: false,
      };
    case SET_RAW_FILE_DATA_FAILURE:
      return {
        ...state,
        file: null,
        filename: null,
        fileHeaders: null,
        rawFileData: null,
        rawFileDataError: action.error,
      };
    case SET_VALIDATED_FILE_DATA_SUCCESS:
      return {
        ...state,
        validatedFileData: action.data.updatedFileData,
        validatedFileDataErrors: action.data.errors,
        validatedFileHeaderErrors: action.data.headerErrors,
      };
    case CLEAR_REDUX_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
