export class ModulesEnums {
  static defaultModuleId = 10;
  static defaultFormId = 23;
  static defaultSectionId = 436;

  static defaultModuleName = "Inspections";
  static defaultFormName = "Master Inspection Template";
  // static defaultFormName = "Office Inspection";
  static defaultSectionName = "Details";

  static fieldTypes = {
    TEXTUAL_QUESTIONNAIRE: "TEXTUAL_QUESTIONNAIRE",
    NUMERIC_QUESTIONNAIRE: "NUMERIC_QUESTIONNAIRE"
  };
}
