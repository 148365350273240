import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./js";
import { IntlProvider } from "react-intl";
import { AuthProvider, UserProvider } from "@myosh/myosh-login";
import { BrowserRouter } from "react-router-dom";
import UsernameGuard from "./components/UsernameGuard";

import messages_en from "./i18n/locales/en.json";

const messages = {
  en: messages_en,
};
const language = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en" messages={messages[language]}>
      <BrowserRouter>
        <AuthProvider>
          <UserProvider>
            <UsernameGuard>
            <App />
            </UsernameGuard>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
