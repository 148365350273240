import React, { Fragment, useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { getFile, getFilename } from "../../js/files/selectors";
import {
  getFormRequest,
  getModuleRequest,
  getModulesRequest,
  getLocalesRequest,
  setInspectionFormData,
  setInspectionFormErrors,
} from "../../js/modules/actions";
import {
  getDefaultForm,
  getDefaultModule,
  getDefaultSection,
  getErrorTargets,
  getForms,
  getFormsById,
  getInspectionFormData,
  getInspectionFormErrors,
  getLoadingTargets,
  getModules,
  getModulesById,
  getModulesError,
  getSections,
  getSectionsById,
  getLocalesError,
  getLocalesLoading,
} from "../../js/modules/selectors";
import { DropzoneArea } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    width: "80%",
    margin: "0 auto",
  },
  inputs: {
    width: "100%",
  },
  input: {
    display: "none",
  },
  fileButtonNormal: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    textAlign: "left",
    boxShadow: "none",
    border: "1px solid #bababa",
    color: "gray",
  },
  fileButtonError: {
    width: "100%",
    height: "100%",
    textAlign: "left",
    boxShadow: "none",
  },
  header: {
    marginBottom: "1rem",
  },
  dropzone: {
    border: "1px solid red",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

const messages = defineMessages({
  description1: {
    id: "StepTwo.description1",
    defaultMessage: `Select the target module where this form should be created. You will also need to select a source form to clone,
       and specify where the sections and questions should be inserted.`,
  },
  description2: {
    id: "StepTwo.description2",
    defaultMessage: `All other form settings including security will be copied from your selected template form.`,
  },
  templateName: {
    id: "StepTwo.templateName",
    defaultMessage: `New Form Name`,
  },
  targetModule: {
    id: "StepTwo.targetModule",
    defaultMessage: `Target module`,
  },
  formToClone: {
    id: "StepTwo.formToClone",
    defaultMessage: `Form to clone`,
  },
  enableScore: {
    id: "StepTwo.enableScore",
    defaultMessage: `Enable scores per section`,
  },
  insertQuestionsAfterSection: {
    id: "StepTwo.insertQuestionsAfterSection",
    defaultMessage: `Insert questions after section`,
  },
  uploadFile: {
    id: "StepTwo.uploadFile",
    defaultMessage: `Upload file`,
  },
  selectTargets: {
    id: "StepTwo.selectTargets",
    defaultMessage: "Select targets",
  },
  dataError: {
    id: "StepTwo.dataError",
    defaultMessage: "Sorry, there has been an error while retrieving data.",
  },
});

function StepTwo({
  intl: { formatMessage },
  filename,
  handleFileChange,
  getModules,
  getLocales,
  getModule,
  getForm,
  modules,
  modulesById,
  forms,
  formsById,
  sections,
  sectionsById,
  defaultModule,
  defaultForm,
  defaultSection,
  setFormData,
  formErrors,
  setFormErrors,
  formData: { templateName, selectedModule, selectedForm, selectedSection },
  targetsError,
  targetsLoading,
  onDelete,
  file,
}) {
  const classes = useStyles();
  const [name, setName] = useState(undefined);
  const [moduleOption, setModuleOption] = useState(undefined);
  const [formOption, setFormOption] = useState(undefined);
  const [sectionOption, setSectionOption] = useState(undefined);
  const [enableScore, setEnableScore] = useState(true);

  useEffect(() => {
    if (!selectedModule) getModules();

    setFormData({
      enableScore: true
    })

    getLocales();
  }, []);

  useEffect(() => {
    if (defaultModule && !selectedForm) getModule(defaultModule.id);
  }, [defaultModule]);

  useEffect(() => {
    if (defaultModule && defaultForm) getForm(defaultModule.id, defaultForm.id);
  }, [defaultForm]);

  // Handlers
  const handleTemplateNameChange = (event) => {
    setName(event.target.value);
    setFormData({ templateName: event.target.value });
    setFormErrors({});
  };

  const handleModuleChange = (event) => {
    setModuleOption(event.target.value);
    setFormOption(undefined);
    setSectionOption(undefined);

    getModule(event.target.value);

    setFormData({
      selectedModule: event.target.value,
      selectedForm: "",
      selectedSection: "",
    });
    setFormErrors({});
  };

  const handleFormChange = (event) => {
    setFormOption(event.target.value);
    setSectionOption(undefined);
    getForm(selectedModule, event.target.value);

    setFormData({
      selectedForm: event.target.value,
      selectedSection: "",
    });
    setFormErrors({});
  };

  const handleSectionChange = (event) => {
    setSectionOption(event.target.value);

    setFormData({ selectedSection: event.target.value });
    setFormErrors({});
  };

  const handleScoreEnable = (event) => {
    setEnableScore(event.target.checked);

    setFormData({enableScore: event.target.checked})
  }

  // Arrange handlers

  const arrangeTemplateName = () => {
    return name !== undefined ? name : templateName;
  };

  const arrangeModule = () => {
    return moduleOption !== undefined
      ? moduleOption
      : selectedModule
      ? selectedModule
      : "";
  };

  const arrangeForm = () => {
    return formOption !== undefined
      ? formOption
      : selectedForm
      ? selectedForm
      : "";
  };

  const arrangeSection = () => {
    return sectionOption !== undefined
      ? sectionOption
      : selectedSection
      ? selectedSection
      : "";
  };

  // Select options
  const sortedModules = modules.sort((a, b) =>
    a?.caption?.translations
      ?.filter((el) => el?.localeId === 1)[0]
      ?.value?.toLowerCase() >
    b?.caption?.translations
      ?.filter((el) => el?.localeId === 1)[0]
      ?.value?.toLowerCase()
      ? 1
      : -1
  );
  const modulesOptions = sortedModules.map((module) => ({
    value: module.id,
    label: module.caption.translations.filter((el) => el.localeId === 1)[0]
      .value,
  }));

  const sortedForms = forms.sort((a, b) =>
    a?.caption?.translations
      ?.filter((el) => el?.localeId === 1)[0]
      ?.value?.toLowerCase() >
    b?.caption?.translations
      ?.filter((el) => el?.localeId === 1)[0]
      ?.value?.toLowerCase()
      ? 1
      : -1
  );
  const formsOptions = sortedForms.map((form) => ({
    value: form.id,
    label: form.caption.translations.filter((el) => el.localeId === 1)[0].value,
  }));

  const sectionsOptions = sections.map((section) => ({
    value: section.id,
    label: section.caption.translations.filter((el) => el.localeId === 1)[0]
      .value,
  }));

  let createdFile = file
    ? new File([filename], file.path, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    : null;

  return (
    <Fragment>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {formatMessage(messages.selectTargets)}
      </Typography>
      {targetsLoading ? (
        <div align="center" style={{ marginTop: "60px" }}>
          <CircularProgress />
        </div>
      ) : targetsError ? (
        <Alert severity="error">{formatMessage(messages.dataError)}</Alert>
      ) : (
        <span>
          <div style={{ marginBottom: "40px" }}>
            {formatMessage(messages.description1)}
          </div>
          <div>{formatMessage(messages.description2)}</div>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} xs={12}>
                <TextField
                  className={classes.inputs}
                  id="templateName"
                  label={formatMessage(messages.templateName)}
                  value={arrangeTemplateName()}
                  onChange={handleTemplateNameChange}
                  error={formErrors.templateName}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <FormControl
                  className={classes.inputs}
                  error={formErrors.selectedModule}
                >
                  <InputLabel id="targetModuleLabel">
                    {formatMessage(messages.targetModule)}
                  </InputLabel>
                  <Select
                    labelId="targetModuleLabel"
                    id="targetModule"
                    className={classes.inputs}
                    value={arrangeModule()}
                    onChange={handleModuleChange}
                    label={formatMessage(messages.targetModule)}
                  >
                    {modulesOptions.map((module) => (
                      <MenuItem key={module.value} value={module.value}>
                        {module.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <FormControl
                  className={classes.inputs}
                  error={formErrors.selectedForm}
                >
                  <InputLabel id="formToCloneLabel">
                    {formatMessage(messages.formToClone)}
                  </InputLabel>
                  <Select
                    labelId="formToCloneLabel"
                    id="formToClone"
                    className={classes.inputs}
                    value={arrangeForm()}
                    onChange={handleFormChange}
                    label={formatMessage(messages.formToClone)}
                  >
                    {formsOptions.map((form) => (
                      <MenuItem key={form.value} value={form.value}>
                        {form.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <FormControl
                  className={classes.inputs}
                  error={formErrors.selectedSection}
                >
                  <InputLabel id="insertQuestionsAfterSectionLabel">
                    {formatMessage(messages.insertQuestionsAfterSection)}
                  </InputLabel>
                  <Select
                    labelId="insertQuestionsAfterSectionLabel"
                    id="insertQuestionsAfterSection"
                    className={classes.inputs}
                    value={arrangeSection()}
                    onChange={handleSectionChange}
                    label={formatMessage(messages.insertQuestionsAfterSection)}
                  >
                    {sectionsOptions.map((section) => (
                      <MenuItem key={section.value} value={section.value}>
                        {section.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <FormControl className={classes.inputs}>
                  <FormControlLabel
                    id="enableScore"
                    control={<Checkbox color="primary" checked={enableScore} onChange={handleScoreEnable}/>}
                    label={formatMessage(messages.enableScore)}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <DropzoneArea
                  onChange={handleFileChange}
                  acceptedFiles={[
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ]}
                  filesLimit={1}
                  style={{ border: "1px solid red" }}
                  dropzoneClass={formErrors.filename ? classes.dropzone : ""}
                  onDelete={onDelete}
                  initialFiles={createdFile ? [createdFile] : []}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Selected files"
                />
                {/* <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  className={classes.input}
                  id="file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="file" style={{ width: "100%", height: "100%" }}>
                  <Button
                    variant={`${
                      formErrors.filename ? "outlined" : "contained"
                    }`}
                    className={`${
                      formErrors.filename
                        ? classes.fileButtonError
                        : classes.fileButtonNormal
                    }`}
                    component="span"
                    color={`${formErrors.filename ? "secondary" : ""}`}
                  >
                    {filename ? filename : formatMessage(messages.uploadFile)}
                  </Button>
                </label> */}
              </Grid>
            </Grid>
          </form>
        </span>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    file: getFile(state),
    filename: getFilename(state),
    modules: getModules(state),
    modulesById: getModulesById(state),
    forms: getForms(state),
    formsById: getFormsById(state),
    sections: getSections(state),
    sectionsById: getSectionsById(state),
    defaultModule: getDefaultModule(state),
    defaultForm: getDefaultForm(state),
    defaultSection: getDefaultSection(state),
    formErrors: getInspectionFormErrors(state),
    formData: getInspectionFormData(state),
    modulesError: getModulesError(state),
    targetsLoading: getLoadingTargets(state),
    targetsError: getErrorTargets(state),
    localesError: getLocalesError(state),
    localesLoading: getLocalesLoading(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getModules: () => dispatch(getModulesRequest()),
    getModule: (id) => dispatch(getModuleRequest(id)),
    getLocales: () => dispatch(getLocalesRequest()),
    getForm: (moduleId, formId) => dispatch(getFormRequest(moduleId, formId)),
    setFormData: (data) => dispatch(setInspectionFormData(data)),
    setFormErrors: (err) => dispatch(setInspectionFormErrors(err)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StepTwo)
);
