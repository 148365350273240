import { put, call, takeLatest } from "redux-saga/effects";
import { api } from "../api";
import {
  getFormFailure,
  getFormSuccess,
  getModuleFailure,
  getModulesFailure,
  getModulesSuccess,
  getModuleSuccess,
  getLocalesSuccess,
  getLocalesFailure,
} from "./actions";
import {
  GET_FORM_REQUEST,
  GET_MODULES_REQUEST,
  GET_MODULE_REQUEST,
  GET_LOCALES_REQUEST
} from "./types";

function* getModules() {
  try {
    const response = yield call(api.modules.getModules);
    yield put(
      getModulesSuccess({
        data: response.status === 204 ? [] : response.data.items,
        total: response.data.size,
      })
    );
  } catch (error) {
    yield put(getModulesFailure(error));
  }
}

function* getLocales() {
  try {
    const response = yield call(api.modules.getLocales);
    yield put(
      getLocalesSuccess({
        data: response.status === 204 ? [] : response.data.items,
        total: response.data.size,
      })
    );
  } catch (error) {
    yield put(getLocalesFailure(error));
  }
}

function* getModule(action) {
  try {
    const response = yield call(api.modules.getModule, action.id);
    yield put(
      getModuleSuccess({
        forms: response.status === 204 ? {} : response.data.result.forms,
      })
    );
  } catch (error) {
    yield put(getModuleFailure(error));
  }
}

function* getForm(action) {
  try {
    const response = yield call(
      api.modules.getForm,
      action.moduleId,
      action.formId
    );
    yield put(
      getFormSuccess({
        sections:
          response.status === 204 ? {} : response.data.result.fieldGroups,
        clonedForm: action.isFromCloning ? response.data.result : null,
        updatedClonedForm:
          action.isFromAddNewSections && !action.isFromCloning
            ? response.data.result
            : null,
      })
    );
  } catch (error) {
    yield put(getFormFailure(error));
  }
}

export default function* modulesSaga() {
  yield takeLatest(GET_MODULES_REQUEST, getModules);
  yield takeLatest(GET_MODULE_REQUEST, getModule);
  yield takeLatest(GET_FORM_REQUEST, getForm);
  yield takeLatest(GET_LOCALES_REQUEST, getLocales)
}
