import { CLEAR_REDUX_STATE } from "../files/types";
import { GET_FORM_FAILURE } from "../modules/types";
import {
  CLONE_FORM_FAILURE,
  CLONE_FORM_REQUEST,
  CLONE_FORM_SUCCESS,
  UPDATE_MODULE_FORM_FAILURE,
  UPDATE_MODULE_FORM_REQUEST,
  UPDATE_MODULE_FORM_SUCCESS,
} from "./types";

const initialState = {
  cloneFormLoading: false,
  cloneFormError: false,
  cloneFormSuccess: false,

  updateModuleFormLoading: false,
  updateModuleFormError: false,
  updateModuleFormSuccess: false,

  updateModuleFormGrandScoreError: false,
  updateModuleFormGrandScoreSuccess: false,

  creationLoading: false,
  creationError: false,

  createdFormId: null,
  updatedFormId: null,

  getFormLoading: false,
  getFormError: false,
};

export default function modules(state = initialState, action = {}) {
  switch (action.type) {
    case CLONE_FORM_REQUEST:
      return {
        ...state,
        cloneFormLoading: true,
        cloneFormError: false,
        cloneFormSuccess: false,

        creationError: false,
        creationLoading: true,
      };
    case CLONE_FORM_SUCCESS:
      return {
        ...state,
        createdFormId: action.id,
        cloneFormLoading: false,
        cloneFormError: false,
        cloneFormSuccess: true,
      };
    case CLONE_FORM_FAILURE:
      return {
        ...state,
        cloneFormLoading: false,
        cloneFormError: action.error,
        cloneFormSuccess: false,

        creationError: action.error,
        creationLoading: false,
      };

    case UPDATE_MODULE_FORM_REQUEST: {
      return {
        ...state,
        updateModuleFormLoading: true,
        updateModuleFormError: false,
        // updateModuleFormSuccess: false,

        updateModuleFormGrandScoreError: false,
        // updateModuleFormGrandScoreSuccess: false,
      };
    }
      
    case UPDATE_MODULE_FORM_SUCCESS:
      return {
        ...state,

        updateModuleFormLoading: false,
        updateModuleFormError: false,
        updateModuleFormSuccess:
          state.updateModuleFormSuccess || action.addingNewSections,

        updateModuleFormGrandScoreError: false,
        updateModuleFormGrandScoreSuccess: !action.addingNewSections,

        creationLoading: action.addingNewSections ? true : false,
      };
    case UPDATE_MODULE_FORM_FAILURE:
      return {
        ...state,
        updateModuleFormLoading: false,
        updateModuleFormError: action.addingNewSections
          ? action.error
          : state.updateModuleFormError,
        updateModuleFormSuccess: action.addingNewSections
          ? false
          : state.updateModuleFormSuccess,

        updateModuleFormGrandScoreError: action.addingNewSections
          ? state.updateModuleFormGrandScoreError
          : action.error,
        updateModuleFormGrandScoreSuccess: action.addingNewSections
          ? state.updateModuleFormGrandScoreSuccess
          : false,

        creationError: action.error,
        creationLoading: false,
      };
    case GET_FORM_FAILURE:
      return {
        ...state,
        // creationError: action.error,
        creationLoading: false,
        getFormError: action.error,
      };
    case CLEAR_REDUX_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
