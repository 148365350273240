import React, { useState } from "react";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { defineMessages, injectIntl } from "react-intl";
import { useAuth } from "@myosh/myosh-login";
import {
  makeStyles,
  Stepper,
  Step,
  Button,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import {
  clearReduxState,
  setRawFileDataFailure,
  setRawFileDataSuccess,
  setValidatedFileDataSuccess,
} from "../../js/files/actions";
import Copyright from "../../components/Copyright";
import { FileEnums } from "../../js/files/FilesUtility";
import { Fragment } from "react";
import StepLabelWithStyles from "./StepLabelWithStyles";
import { getInspectionFormData } from "../../js/modules/selectors";
import {
  setInspectionFormData,
  setInspectionFormErrors,
} from "../../js/modules/actions";
import { getFilename } from "../../js/files/selectors";
import { getCreationLoading } from "../../js/creation/selectors";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  buttons: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: "right",
  },
  layout: {
    paddingTop: theme.spacing(7),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
}));

const messages = defineMessages({
  title: {
    id: "Login.title",
    defaultMessage: "Inspection Form Loader",
  },
  downloadCompleteTemplate: {
    id: "Login.downloadCompleteTemplate",
    defaultMessage: "Complete template",
  },
  selectTargets: {
    id: "Login.selectTargets",
    defaultMessage: "Select targets",
  },
  review: {
    id: "Login.review",
    defaultMessage: "Review",
  },
  templateCreated: {
    id: "Login.templateCreated",
    defaultMessage: "Form created",
  },
  stepOneDescription: {
    id: "Login.stepOneDescription",
    defaultMessage:
      "Download template and add your Inspection Form data including sections, questions and answers. Use a separate file for each Inspection Form you wish to create.",
  },
  downloadFile: {
    id: "Login.downloadFile",
    defaultMessage: "Click here to download the file.",
  },
  back: {
    id: "Login.back",
    defaultMessage: "Back",
  },
  done: {
    id: "Login.done",
    defaultMessage: "Done",
  },
  next: {
    id: "Login.next",
    defaultMessage: "Next",
  },
  importAnotherForm: {
    id: "Login.importAnotherForm",
    defaultMessage: "Import another form",
  },
});

function HorizontalLinearStepper({
  intl: { formatMessage },
  setFileData,
  setFileDataFailure,
  clearReduxState,
  formData,
  setFormErrors,
  filename,
  setFormData,
  setValidatedFileData,
  creationLoading,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [stepThreeValid, setStepThreeValid] = useState(false);
  const { logout } = useAuth();

  const steps = [
    formatMessage(messages.downloadCompleteTemplate),
    formatMessage(messages.selectTargets),
    formatMessage(messages.review),
    formatMessage(messages.templateCreated),
  ];

  const validateForm = () => {
    const errors = {};
    if (!formData.templateName) errors.templateName = true;
    if (!formData.selectedModule) errors.selectedModule = true;
    if (!formData.selectedForm) errors.selectedForm = true;
    if (!formData.selectedSection) errors.selectedSection = true;
    if (!filename) errors.filename = true;

    return errors;
  };

  const handleLogout = () => {
    logout();
  };

  const handleNext = () => {
    if (activeStep !== 3 && activeStep !== 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 3) {
      handleLogout();
    }

    if (activeStep === 1) {
      const errors = validateForm();
      if (Object.keys(errors).length) {
        setFormErrors(errors);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 0) {
      setFormErrors({});
      setFormData({
        templateName: "",
        selectedModule: "",
        selectedForm: "",
        selectedSection: "",
      });
    }
    if (activeStep === 2) {
      console.log(formData);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileChange = (files) => {
    const file = files[0];
    if (file) {
      setFormErrors({});
      setValidatedFileData({
        updatedFileData: [],
        errors: [],
        headerErrors: [],
      });

      const reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });

        if (workbook.SheetNames) {
          var fileRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[workbook.SheetNames[0]]
          );
          let headers = get_header_row(workbook.Sheets[workbook.SheetNames[0]]);
          const allowedHeaders = FileEnums.FILE_HEADERS;
          headers = headers.filter(
            (header) => allowedHeaders.indexOf(header) > -1
          );
          if (file.path)
            setFileData({ file, filename: file.name, fileRows, headers });
        }
      };

      reader.onerror = function (error) {
        setFileDataFailure(error);
      };

      reader.readAsBinaryString(file);
    }
  };

  function get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    var C,
      R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell =
        sheet[
          XLSX.utils.encode_cell({ c: C, r: R })
        ]; /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }

  const handleImportAnotherForm = () => {
    setActiveStep(0);
    clearReduxState();
  };

  const onDelete = () => {
    setFileData({ file: null, filename: null, fileRows: null, headers: null });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return (
          <StepTwo handleFileChange={handleFileChange} onDelete={onDelete} />
        );
      case 2:
        return (
          <StepThree
            setStepThreeValid={setStepThreeValid}
            stepThreeValid={stepThreeValid}
          />
        );
      case 3:
        return <StepFour />;
      default:
        return "Unknown step";
    }
  }

  return (
    <Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {formatMessage(messages.title)}
          </Typography>
          <Stepper activeStep={activeStep} style={{ backgroundColor: "white" }}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabelWithStyles>
                    <span>{label}</span>
                  </StepLabelWithStyles>
                </Step>
              );
            })}
          </Stepper>

          <div className={classes.content}>
            <div>
              <div className={classes.instructions}>
                {getStepContent(activeStep)}
              </div>
              <div className={classes.buttons}>
                {activeStep !== 0 && activeStep !== steps.length - 1 && (
                  <Button
                    onClick={handleBack}
                    className={classes.button}
                    color="default"
                  >
                    {formatMessage(messages.back)}
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button
                    // variant="contained"
                    color="default"
                    onClick={handleImportAnotherForm}
                    className={classes.button}
                    disabled={creationLoading}
                  >
                    {formatMessage(messages.importAnotherForm)}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={
                    (activeStep === 2 && !stepThreeValid) ||
                    (activeStep === steps.length - 1 && creationLoading)
                  }
                >
                  {activeStep === steps.length - 1
                    ? formatMessage(messages.done)
                    : formatMessage(messages.next)}
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </main>
      <Box mt={8} mb={8}>
        <Copyright />
      </Box>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    formData: getInspectionFormData(state),
    filename: getFilename(state),
    creationLoading: getCreationLoading(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setFileData: (fileRows) => dispatch(setRawFileDataSuccess(fileRows)),
    setFileDataFailure: (data) => dispatch(setRawFileDataFailure(data)),
    clearReduxState: () => dispatch(clearReduxState()),
    setFormErrors: (err) => dispatch(setInspectionFormErrors(err)),
    setFormData: (data) => dispatch(setInspectionFormData(data)),
    setValidatedFileData: (data) => dispatch(setValidatedFileDataSuccess(data)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(HorizontalLinearStepper)
);
