import { createSelector } from "reselect";

export const getLocalesLoading = (state) => state.modules.getLocalesLoading;
export const getLocalesError = (state) => state.modules.getLocalesError;

export const getLocalesTotal = (state) => state.modules.totalLocales;
export const getLocales = (state) => state.modules.locales;

export const getModulesLoading = (state) => state.modules.getModulesLoading;
export const getModulesError = (state) => state.modules.getModulesError;

export const getModuleById = (state, id) => state.modules.modulesById[id];
export const getModulesById = (state) => state.modules.modulesById || {};
export const getModulesAllIds = (state) => state.modules.modulesAllIds || [];
export const getModules = createSelector(
  [getModulesById, getModulesAllIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);

export const getFormById = (state, id) => state.modules.formsById[id];
export const getFormsById = (state) => state.modules.formsById || {};
export const getFormsAllIds = (state) => state.modules.formsAllIds || [];
export const getForms = createSelector(
  [getFormsById, getFormsAllIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);

export const getSectionById = (state, id) => state.modules.sectionsById[id];
export const getSectionsById = (state) => state.modules.sectionsById || {};
export const getSectionsAllIds = (state) => state.modules.sectionsAllIds || [];
export const getSections = createSelector(
  [getSectionsById, getSectionsAllIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);

export const getDefaultSection = (state) => state.modules.defaultSection;
export const getDefaultModule = (state) => state.modules.defaultModule;
export const getDefaultForm = (state) => state.modules.defaultForm;

export const getInspectionFormData = (state) =>
  state.modules.inspectionFormData;
export const getInspectionFormErrors = (state) =>
  state.modules.inspectionFormErrors || {};

export const getLoadingTargets = (state) => state.modules.loadingTargets;
export const getErrorTargets = (state) => state.modules.errorTargets;

export const getClonedForm = (state) => state.modules.clonedForm;
export const getUpdatedClonedForm = (state) => state.modules.updatedClonedForm;
