import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Typography, makeStyles } from "@material-ui/core";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";

const useStyle = makeStyles((theme) => ({
  header: {
    marginBottom: "1rem",
  },
}));

const messages = defineMessages({
  stepOneDescription: {
    id: "StepOne.stepOneDescription",
    defaultMessage:
      "Download template and add your Inspection Form data including sections, questions and answers. Use a separate file for each Inspection Form you wish to create.",
  },
  downloadFile: {
    id: "StepOne.downloadFile",
    defaultMessage: "Get the template",
  },
  downloadCompleteTemplate: {
    id: "StepOne.downloadCompleteTemplate",
    defaultMessage: "Download and complete Template",
  },
});

function StepOne({ intl: { formatMessage } }) {
  const classes = useStyle();

  return (
    <span>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {formatMessage(messages.downloadCompleteTemplate)}
      </Typography>
      <div style={{ marginBottom: "40px" }}>
        {formatMessage(messages.stepOneDescription)}
      </div>
      <div
        style={{
          color: "green",
          textAlign: "center",
        }}
      >
        <a
          href={"./IFL Upload Template.xlsx"}
          download
          style={{
            textDecoration: "none",
            color: "green",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "green",
              display: "inline-block",
            }}
          >
            <GetAppSharpIcon />
          </div>
          <div
            style={{
              color: "green",
              display: "inline-block",
            }}
          >
            {formatMessage(messages.downloadFile)}
          </div>
        </a>
      </div>
    </span>
  );
}

export default injectIntl(StepOne);
