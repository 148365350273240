export const getRawFileData = (state) => state.files.rawFileData || [];
export const getRawFileDataError = (state) => state.files.rawFileDataError;
export const getFileHeaders = (state) => state.files.fileHeaders || [];
export const getFilename = (state) => state.files.filename;
export const getFile = (state) => state.files.file;

export const getValidatedFileData = (state) =>
  state.files.validatedFileData || [];
export const getValidatedFileDataErrors = (state) =>
  state.files.validatedFileDataErrors || [];
export const getValidatedFileHeaderErrors = (state) =>
  state.files.validatedFileHeaderErrors || [];
