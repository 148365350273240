import {
  GET_FORM_FAILURE,
  GET_FORM_REQUEST,
  GET_FORM_SUCCESS,
  GET_MODULES_FAILURE,
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULE_FAILURE,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_LOCALES_REQUEST,
  GET_LOCALES_SUCCESS,
  GET_LOCALES_FAILURE,
  SET_INSPECTION_FORM_DATA,
  SET_INSPECTION_FORM_ERRORS,
} from "./types";
import keyBy from "lodash.keyby";
import { ModulesEnums } from "./ModulesUtility";
import { CLEAR_REDUX_STATE } from "../files/types";

const initialState = {
  getLocalesLoading: false,
  getLocalesError: false,
  totalLocales: 0,
  locales: [],

  loadingTargets: false,
  errorTargets: false,

  getModulesLoading: false,
  getModulesError: false,
  modulesAllIds: [],
  modulesById: {},
  totalModules: 0,
  defaultModule: "",

  getModuleLoading: true,
  getModuleError: false,
  formsById: {},
  formsAllIds: [],
  defaultForm: "",

  getFormLoading: true,
  getFormError: false,
  sectionsById: {},
  sectionsAllIds: [],
  defaultSection: "",
  clonedForm: false,

  inspectionFormData: {
    templateName: "",
    selectedModule: "",
    selectedForm: "",
    selectedSection: "",
    enableScore: true
  },
  inspectionFormErrors: {},
};

export default function modules(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MODULES_REQUEST:
      return {
        ...state,
        getModulesLoading: true,
        getModulesError: false,
        //
        loadingTargets: true,
      };
    case GET_MODULES_SUCCESS:
      const defaultModule = action.data.data.find((el) =>
        el.caption.translations.find(
          (el) => el.value === ModulesEnums.defaultModuleName
        )
      );

      return {
        ...state,
        getModulesLoading: true,
        getModulesError: false,
        modulesAllIds: action.data.data.map((el) => el.id),
        modulesById: keyBy(action.data.data, (el) => el.id),
        totalModules: action.data.total,
        loadingTargets: Boolean(defaultModule),
        defaultModule,
        inspectionFormData: {
          ...state.inspectionFormData,
          selectedModule: defaultModule
            ? defaultModule.id
            : state.inspectionFormData.selectedModule,
        },
      };
    case GET_MODULES_FAILURE:
      return {
        ...state,
        getModulesLoading: true,
        getModulesError: action.error,
        //
        errorTargets: action.error,
        loadingTargets: false,
      };

    case GET_LOCALES_REQUEST:
      return {
        ...state,
        getLocalesLoading: true,
        getLocalesError: false,
      };

    case GET_LOCALES_SUCCESS: {
      return {
        ...state,
        getLocalesError: false,
        getLocalesLoading: false,
        locales: action.data.data,
        totalLocales: action.data.data.length,
      };
    }
      

    case GET_LOCALES_FAILURE:
      return {
        ...state,
        getLocalesError: true,
        getLocalesLoading: false,
      };
    case GET_MODULE_REQUEST:
      return {
        ...state,
        getModuleLoading: true,
        getModuleError: false,
      };
    case GET_MODULE_SUCCESS:
      const defaultForm = action.data.forms.find((el) =>
        el.caption.translations.find(
          (el) => el.value === ModulesEnums.defaultFormName
        )
      );
      return {
        ...state,
        getModuleLoading: true,
        getModuleError: false,
        formsAllIds: action.data.forms.map((el) => el.id),
        formsById: keyBy(action.data.forms, (el) => el.id),
        defaultForm,
        loadingTargets: Boolean(defaultForm),
        sectionsAllIds: [],
        sectionsById: {},
        inspectionFormData: {
          ...state.inspectionFormData,
          selectedForm: defaultForm
            ? defaultForm.id
            : state.inspectionFormData.selectedForm,
        },
      };
    case GET_MODULE_FAILURE:
      return {
        ...state,
        getModuleLoading: true,
        getModuleError: action.error,
        //
        errorTargets: action.error,
        loadingTargets: false,
      };
    case GET_FORM_REQUEST:
      return {
        ...state,
        getFormLoading: true,
        getFormError: false,
      };
    case GET_FORM_SUCCESS:
      const defaultSection = action.data.sections.find((el) =>
        el.caption.translations.find(
          (el) => el.value === ModulesEnums.defaultSectionName
        )
      );
      return {
        ...state,
        getFormLoading: true,
        getFormError: false,
        sectionsAllIds: action.data.sections.map((el) => el.id),
        sectionsById: keyBy(action.data.sections, (el) => el.id),
        defaultSection,
        inspectionFormData: {
          ...state.inspectionFormData,
          selectedSection: defaultSection
            ? defaultSection.id
            : state.inspectionFormData.selectedSection,
        },
        loadingTargets: false,
        clonedForm: action.data.clonedForm,
        updatedClonedForm: action.data.updatedClonedForm,
      };
    case GET_FORM_FAILURE:
      return {
        ...state,
        getFormLoading: true,
        getFormError: action.error,
        //
        errorTargets: action.error,
        loadingTargets: false,
      };
    case SET_INSPECTION_FORM_DATA:
      return {
        ...state,
        inspectionFormData: {
          ...state.inspectionFormData,
          ...action.data,
        },
      };
    case SET_INSPECTION_FORM_ERRORS:
      return {
        ...state,
        inspectionFormErrors: action.errors,
      };
    case CLEAR_REDUX_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
