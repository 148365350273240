import { request } from "../request";

export const modules = {
  getModules: () =>
    request({
      url: "/modules",
      method: "GET",
    }),
  getModule: (id) =>
    request({
      url: `/modules/${id}`,
      method: "GET",
    }),
  getForm: (moduleId, formId) =>
    request({
      url: `/modules/${moduleId}/forms/${formId}`,
      method: "GET",
    }),
  getLocales: () => 
    request({
      url: "/locales",
      method: "GET"
    })
};
