import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl } from "react-intl";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import {
  cloneFormRequest,
  updateModuleFormRequest,
} from "../../js/creation/actions";
import { getLocales, getLocalesTotal } from "../../js/modules/selectors";
import {
  getCloneFormError,
  getCloneFormLoading,
  getCloneFormSuccess,
  getCreatedFormId,
  getCreationError,
  getCreationLoading,
  getUpdateModuleFormError,
  getUpdateModuleFormLoading,
  getUpdateModuleFormSuccess,
  getUpdatedFormId,
  getUpdateModuleFormGrandScoreError,
  getUpdatedFormError,
  getUpdateModuleFormGrandScoreSuccess,
} from "../../js/creation/selectors";
import {
  getClonedForm,
  getInspectionFormData,
  getSectionById,
  getUpdatedClonedForm,
} from "../../js/modules/selectors";
import Alert from "@material-ui/lab/Alert";
import { getValidatedFileData } from "../../js/files/selectors";
import { FileEnums } from "../../js/files/FilesUtility";
import groupBy from "lodash.groupby";
import { getFormRequest } from "../../js/modules/actions";
import { ModulesEnums } from "../../js/modules/ModulesUtility";


function capitalizeWord(word) {
  let wordTmp = word;
  if(word[word.length -1] !== "s"){
    wordTmp += "s"
  }
  return wordTmp.split("").map((letter, index) => {
    if(index === 0) {
      return letter.toUpperCase();
    }
    return letter.toLowerCase();
  }).join("");
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  desc: {
    marginBottom: theme.spacing(12),
  },
  header: {
    marginBottom: "1rem",
  },
}));

const messages = defineMessages({
  success: {
    id: "StepFour.success",
    defaultMessage: `Success!`,
  },
  description: {
    id: "StepFour.description",
    defaultMessage: `Please logout and back in to Viking to view your new form.`,
  },
  templateCreated: {
    id: "StepFour.templateCreated",
    defaultMessage: "Template created",
  },
  cloneFormSuccess: {
    id: "StepFour.cloneFormSuccess",
    defaultMessage: "Form created successfully.",
  },
  cloneFormLoading: {
    id: "StepFour.cloneFormLoading",
    defaultMessage: "Creating your form...",
  },
  cloneFormError: {
    id: "StepFour.cloneFormError",
    defaultMessage: "Form has not been created.",
  },
  updateModuleFormSuccess: {
    id: "StepFour.updateModuleFormSuccess",
    defaultMessage: "New sections added.",
  },
  updateModuleFormLoading: {
    id: "StepFour.updateModuleFormLoading",
    defaultMessage: "Form updating...",
  },
  updateModuleFormError: {
    id: "StepFour.updateModuleFormError",
    defaultMessage: "New sections have not been added.",
  },
  updateModuleFormGrandScoreError: {
    id: "StepFour.updateModuleFormGrandScoreError",
    defaultMessage: "Grand scores have not been added.",
  },
  updateModuleFormGrandScoreSuccess: {
    id: "StepFour.updateModuleFormGrandScoreSuccess",
    defaultMessage: "Grand scores added.",
  },
  serverError: {
    id: "StepFour.serverError",
    defaultMessage: "Sorry there has been a server error.",
  },
});

function getLocaleList(value, locales) {
  return locales.map((locale) => ({
    localeId: locale.id,
    value,
  }));
}

function StepFour({
  intl: { formatMessage },
  // Actions
  cloneForm,
  updateModuleForm,
  // Selectors
  creationLoading,
  creationError,
  formData: { templateName, selectedModule, selectedForm, selectedSection, enableScore },
  createdFormId,
  fileData,
  getForm,
  clonedForm,
  sectionById,
  cloneFormLoading,
  cloneFormError,
  cloneFormSuccess,
  updateFormLoading,
  updateFormError,
  updateFormSuccess,
  // updatedFormId,
  updateFormGrandScoreError,
  updatedClonedForm,
  getFormError,
  updateFormGrandScoreSuccess,
  locales,
  localesCount,
}) {
  const classes = useStyles();
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);

  // Clone form
  useEffect(() => {
    const data = {
      moduleId: selectedModule,
      data: {
        sourceFormId: selectedForm,
        cloneFormCaption: {
          translations: [...getLocaleList(templateName, locales)],
        },
      },
    };

    cloneForm(data);
  }, []);

  // Create new sections in the cloned form
  useEffect(() => {
    if (clonedForm) {
      const sections = groupBy(fileData, (el) => el.Section);
      const newSectionsLength = Object.keys(sections).length;

      let updatedSections = [];
      let sectionName = "";
      let found = false;
      let newSection = null;

      const selectedSectionName =
        sectionById(selectedSection) &&
        sectionById(selectedSection).caption.translations.find(
          (el) => el.localeId === 1
        );
      const userSelectedSection = sectionById(selectedSection);

      clonedForm.fieldGroups.forEach((section, index) => {
        sectionName = section.caption.translations.find(
          (el) => el.localeId === 1
        );

        if (sectionName === selectedSectionName) {
          found = true;
          updatedSections.push(section);

          setStartPosition(index + 1);
          setEndPosition(index + 1 + newSectionsLength);
          Object.keys(sections).forEach((section, secIndex) => {
            newSection = {
              caption: {
                translations: [...getLocaleList(section, locales)],
              },
              position: index + secIndex + 2,
              removed: false,
              displayType: userSelectedSection.displayType,
              accessRights: userSelectedSection.accessRights,
              fields: sections[section].map((row, questionIndex) => {
                const answers = [
                  {
                    caption: {
                      translations: [
                        ...getLocaleList(
                          row[FileEnums.FILE_HEADERS_BYID.Score1],
                          locales
                        ),
                      ],
                    },
                    color: "#0ba600",
                    position: 0,
                    score: 1,
                  },
                ];
                if (row[FileEnums.FILE_HEADERS_BYID.Score_1])
                  answers.push({
                    caption: {
                      translations: [
                        ...getLocaleList(
                          row[FileEnums.FILE_HEADERS_BYID.Score_1],
                          locales
                        ),
                      ],
                    },
                    color: "#d72800",
                    position: 1,
                    score: -1,
                  });
                if (row[FileEnums.FILE_HEADERS_BYID.Score0])
                  answers.push({
                    caption: {
                      translations: [
                        ...getLocaleList(
                          row[FileEnums.FILE_HEADERS_BYID.Score0],
                          locales
                        ),
                      ],
                    },
                    color: "#bab9bb",
                    position: 2,
                    score: 0,
                  });
                if (row[FileEnums.FILE_HEADERS_BYID.Score0_1])
                  answers.push({
                    caption: {
                      translations: [
                        ...getLocaleList(
                          row[FileEnums.FILE_HEADERS_BYID.Score0_1],
                          locales
                        ),
                      ],
                    },
                    color: "#bab9bb",
                    position: 3,
                    score: 0,
                  });
                if (row[FileEnums.FILE_HEADERS_BYID.Score0_2])
                  answers.push({
                    caption: {
                      translations: [
                        ...getLocaleList(
                          row[FileEnums.FILE_HEADERS_BYID.Score0_2],
                          locales
                        ),
                      ],
                    },
                    color: "#bab9bb",
                    position: 4,
                    score: 0,
                  });

                return {
                  fieldType: ModulesEnums.fieldTypes.TEXTUAL_QUESTIONNAIRE,
                  fieldCaption: {
                    translations: [
                      ...getLocaleList(
                        row[FileEnums.FILE_HEADERS_BYID.Questions],
                        locales
                      ),
                    ],
                  },
                  position: questionIndex + 1,
                  showFieldCaption: false,
                  // Comments column
                  allowTextInputWhileAnsweringQuestions:
                    row[FileEnums.FILE_HEADERS_BYID.Comments].toLowerCase() ===
                    FileEnums.COMMENTS_VALUES.yes,
                  // Linked records
                  linkedRecordKeyword:
                    row[FileEnums.FILE_HEADERS_BYID.LinkedRecords].toLowerCase() ===
                    FileEnums.LINKED_RECORDS_VALUES.none
                      ? ""
                      : capitalizeWord(row[FileEnums.FILE_HEADERS_BYID.LinkedRecords]),
                  // Score,
                  linkedRecords:  !(row[FileEnums.FILE_HEADERS_BYID.LinkedRecords].toLowerCase() ===
                  FileEnums.LINKED_RECORDS_VALUES.none),
                  answers: answers,
                  displayScores: false,
                  questions: [
                    {
                      caption: {
                        translations: [
                          ...getLocaleList(
                            row[FileEnums.FILE_HEADERS_BYID.Questions],
                            locales
                          ),
                        ],
                      },
                      // Weighting
                      weight: row[FileEnums.FILE_HEADERS_BYID.Weighting],
                      position: 1,
                    },
                  ],
                };
              }),
            };

            updatedSections.push(newSection);
          });
        } else if (!found) {
          updatedSections.push(section);
        } else {
          updatedSections.push({
            ...section,
            position: section.position + newSectionsLength,
          });
        }
      });

      updatedSections = updatedSections.map((section) => {
        section.fields = section.fields.map((field) => {
          if (
            field &&
            field.fieldType &&
            field.fieldType === ModulesEnums.fieldTypes.TEXTUAL_QUESTIONNAIRE
          ) {
            field.displayScores = false;
          }

          if (
            field &&
            field.fieldType &&
            field.fieldType === ModulesEnums.fieldTypes.NUMERIC_QUESTIONNAIRE
          ) {
            field.displayScores = false;
          }

          if (field && field.buttonCaption) {
            const translation = field.buttonCaption.translations[0].value;
            field.buttonCaption.translations =
              field.buttonCaption.translations.map((t) => ({
                ...t,
                value: translation,
              }));

            return field;
          }

          if (field && field.fieldCaption) {
            const translation = field.fieldCaption.translations[0].value;
            field.fieldCaption.translations =
              field.fieldCaption.translations.map((t) => ({
                ...t,
                value: translation,
              }));

            return field;
          }

          return field;
        });

        return section;
      });

      if (clonedForm.questionRecordListFields) {
        clonedForm.questionRecordListFields =
          clonedForm.questionRecordListFields.map((field) => {
            if (
              field &&
              field.fieldType &&
              field.fieldType === ModulesEnums.fieldTypes.TEXTUAL_QUESTIONNAIRE
            ) {
              field.displayScores = false;
            }

            if (
              field &&
              field.fieldType &&
              field.fieldType === ModulesEnums.fieldTypes.NUMERIC_QUESTIONNAIRE
            ) {
              field.displayScores = false;
            }

            if (
              field &&
              field.questionField &&
              field.questionField.fieldCaption
            ) {
              const translation =
                field.questionField.fieldCaption.translations[0].value;
              field.questionField.fieldCaption.translations =
                field.questionField.fieldCaption.translations.map((t) => ({
                  ...t,
                  value: translation,
                }));

              return field;
            }

            return field;
          });
      }

      if (clonedForm.fieldGroups) {
        clonedForm.fieldGroups = clonedForm.fieldGroups.map((fieldGroup) => {
          if (fieldGroup.fields) {
            fieldGroup.fields = fieldGroup.fields.map((field) => {
              if (
                field &&
                field.fieldType &&
                field.fieldType ===
                  ModulesEnums.fieldTypes.TEXTUAL_QUESTIONNAIRE
              ) {
                field.displayScores = false;
              }

              if (
                field &&
                field.fieldType &&
                field.fieldType ===
                  ModulesEnums.fieldTypes.NUMERIC_QUESTIONNAIRE
              ) {
                field.displayScores = false;
              }
              const translation = field.fieldCaption.translations[0].value;
              field.fieldCaption.translations =
                field.fieldCaption.translations.map((t) => {
                  if (!t.value) {
                    return {
                      ...t,
                      value: translation,
                    };
                  }

                  return t;
                });

              return field;
            });
          }
          return fieldGroup;
        });
      }

      const data = {
        ...clonedForm,
        fieldGroups: updatedSections,
      };

      updateModuleForm({
        moduleId: selectedModule,
        formId: createdFormId,
        data,
        addingNewSections: enableScore,
        enableScore
      });
    }
  }, [clonedForm]);

  // Add GRAND_SCORE
  useEffect(() => {
    if (updatedClonedForm && enableScore) {
      let section,
        fieldIds,
        allIds = [];

      const data = {
        ...updatedClonedForm,
        fieldGroups: updatedClonedForm.fieldGroups.map((el, index) => {
          section = { ...el };

          if (index >= startPosition && index < endPosition) {
            fieldIds = [];
            el.fields.forEach((field) => {
              allIds.push(field.id);
              fieldIds.push(field.id);
            });

            section = {
              ...section,
              fields: [
                ...section.fields,
                {
                  fieldType: "GRAND_SCORE",
                  fieldCaption: {
                    translations: [
                      ...getLocaleList(
                        `Grand score for section: ${section.caption.translations[0].value}`,
                        locales
                      ),
                    ],
                  },
                  showInRecordList: false,
                  availableInRecordList: false,
                  position: section.fields.length + 1,
                  required: false,
                  removed: false,
                  lockDown: false,
                  targetFields: fieldIds,
                  threshold: Math.round(fileData.length * 0.8),
                  thresholdExceededBackground: "#d72800",
                  thresholdBelowBackground: "#d72800",
                },
              ],
            };

            if (index === endPosition - 1) {
              section = {
                ...section,
                fields: [
                  ...section.fields,
                  {
                    fieldType: "GRAND_SCORE",
                    fieldCaption: {
                      translations: [
                        ...getLocaleList(`Overall Grand score`, locales),
                      ],
                    },
                    showInRecordList: false,
                    availableInRecordList: false,
                    position: section.fields.length + 1,
                    required: false,
                    removed: false,
                    lockDown: false,
                    targetFields: allIds,
                    threshold: Math.round(fileData.length * 0.8),
                    thresholdExceededBackground: "#d72800",
                    thresholdBelowBackground: "#d72800",
                  },
                ],
              };
            }
          }

          return section;
        }),
      };

      updateModuleForm({
        moduleId: selectedModule,
        formId: createdFormId,
        data,
      });
    }
  }, [updatedClonedForm]);

  return (
    <span>
      {creationLoading ? (
        <div align="center" style={{ marginTop: "60px" }}>
          <CircularProgress />
          <div>
            {cloneFormLoading && (
              <div>{formatMessage(messages.cloneFormLoading)}</div>
            )}
            {cloneFormSuccess && (
              <div>{formatMessage(messages.cloneFormSuccess)}</div>
            )}
            {updateFormLoading && (
              <div>{formatMessage(messages.updateModuleFormLoading)}</div>
            )}
            {updateFormSuccess && (
              <div>{formatMessage(messages.updateModuleFormSuccess)}</div>
            )}
            {updateFormGrandScoreSuccess && (
              <div>
                {formatMessage(messages.updateModuleFormGrandScoreSuccess)}
              </div>
            )}
          </div>
        </div>
      ) : creationError || getFormError ? (
        <div>
          <Alert severity="error">
            {creationError
              ? creationError
              : formatMessage(messages.serverError)}
          </Alert>
          <div style={{ color: "green" }}>
            {cloneFormSuccess && (
              <div>{formatMessage(messages.cloneFormSuccess)}</div>
            )}
            {updateFormSuccess && (
              <div>{formatMessage(messages.updateModuleFormSuccess)}</div>
            )}
            {updateFormGrandScoreSuccess && (
              <div>
                {formatMessage(messages.updateModuleFormGrandScoreSuccess)}
              </div>
            )}
          </div>
          <div style={{ color: "red" }}>
            {cloneFormError && (
              <div>{formatMessage(messages.cloneFormError)}</div>
            )}
            {updateFormError && (
              <div>{formatMessage(messages.updateModuleFormError)}</div>
            )}
            {updateFormGrandScoreError && (
              <div>
                {formatMessage(messages.updateModuleFormGrandScoreError)}
              </div>
            )}
          </div>
        </div>
      ) : (
        <span>
          <Typography variant="h6" className={classes.header} gutterBottom>
            {formatMessage(messages.templateCreated)}
          </Typography>
          <div className={classes.section}>
            {formatMessage(messages.success)}
          </div>
          <div className={classes.desc}>
            {formatMessage(messages.description)}
          </div>
        </span>
      )}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    creationLoading: getCreationLoading(state),
    creationError: getCreationError(state),
    locales: getLocales(state),
    localesCount: getLocalesTotal(state),
    formData: getInspectionFormData(state),
    createdFormId: getCreatedFormId(state),
    fileData: getValidatedFileData(state),
    clonedForm: getClonedForm(state),
    sectionById: (id) => getSectionById(state, id),
    cloneFormLoading: getCloneFormLoading(state),
    cloneFormError: getCloneFormError(state),
    cloneFormSuccess: getCloneFormSuccess(state),
    updateFormLoading: getUpdateModuleFormLoading(state),
    updateFormError: getUpdateModuleFormError(state),
    updateFormSuccess: getUpdateModuleFormSuccess(state),
    updatedFormId: getUpdatedFormId(state),
    updateFormGrandScoreError: getUpdateModuleFormGrandScoreError(state),
    updateFormGrandScoreSuccess: getUpdateModuleFormGrandScoreSuccess(state),
    updatedClonedForm: getUpdatedClonedForm(state),
    getFormError: getUpdatedFormError(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    cloneForm: (data) => dispatch(cloneFormRequest(data)),
    updateModuleForm: (data) => dispatch(updateModuleFormRequest(data)),
    getForm: (moduleId, formId, isFromCloning) =>
      dispatch(getFormRequest(moduleId, formId, isFromCloning)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StepFour)
);
