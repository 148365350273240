import React, { useEffect } from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  Tooltip,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import Alert from "@material-ui/lab/Alert";
import { FileEnums } from "../../js/files/FilesUtility";
import {
  getFileHeaders,
  getRawFileData,
  getRawFileDataError,
  getValidatedFileData,
  getValidatedFileDataErrors,
  getValidatedFileHeaderErrors,
} from "../../js/files/selectors";
import { setValidatedFileDataSuccess } from "../../js/files/actions";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(5),
  },
  table: {
    minWidth: 1400,
  },
  th: {
    fontWeight: "bold",
  },
  textDanger: {
    color: "red",
  },
  textSuccess: {
    color: "green",
  },
  header: {
    marginBottom: "1rem",
  },
  tableHeight: {
    maxHeight: 640,
  },
}));

const messages = defineMessages({
  section: {
    id: "StepThree.section",
    defaultMessage: `Section`,
  },
  question: {
    id: "StepThree.question",
    defaultMessage: `Questions`,
  },
  feedback: {
    id: "StepThree.feedback",
    defaultMessage: `Feedback`,
  },
  error: {
    id: "StepThree.error",
    defaultMessage: `Error`,
  },
  fileError: {
    id: "StepThree.fileError",
    defaultMessage: `Sorry, an error occured while reading the file.`,
  },
  requiredFields: {
    id: "StepThree.requiredFields",
    defaultMessage: `fields are missing in this row.`,
  },
  requiredField: {
    id: "StepThree.requiredField",
    defaultMessage: `field is missing in this row.`,
  },
  weightingError: {
    id: "StepThree.weightingError",
    defaultMessage: `Weighting value must be a number.`,
  },
  scoreError: {
    id: "StepThree.scoreError",
    defaultMessage: `Score values must be numbers.`,
  },
  commentsError: {
    id: "StepThree.commentsError",
    defaultMessage: `Comments values must be "yes" or "no".`,
  },
  linkedRecordsError: {
    id: "StepThree.linkedRecordsError",
    defaultMessage: `Linked records values must be "Hazards", "Actions" or "None".`,
  },
  correctData: {
    id: "StepThree.correctData",
    defaultMessage: `This row is correct.`,
  },
  headersError: {
    id: "StepThree.headersError",
    defaultMessage: `Missing headers: `,
  },
  correctDocument: {
    id: "StepThree.correctDocument",
    defaultMessage: `The document you uploaded is correct. You can proceed to the next step.`,
  },
  errorDocument: {
    id: "StepThree.errorDocument",
    defaultMessage: `Sorry, the document you uploaded is not correct. Please go to previous step, fix the errors in your file and upload it again.`,
  },
  review: {
    id: "StepThree.review",
    defaultMessage: "Review",
  },
  noData: {
    id: "StepThree.noData",
    defaultMessage: "The file you uploaded does not have any rows with data.",
  },
  formErrors: {
    id: "StepThree.formErrors",
    defaultMessage: "There are some errors for this row.",
  },
});

function StepThree({
  setStepThreeValid,
  fileData,
  fileHeaders,
  fileDataError,
  stepThreeValid,
  setValidatedFileData,
  validatedFileData,
  validatedFileHeaderErrors,
  validatedFileDataErrors,
  intl: { formatMessage },
}) {
  const classes = useStyles();

  const validate = () => {
    const errors = [];
    let headerErrors = [];

    if (fileData.length) {
      Object.values(FileEnums.REQUIRED_FILE_HEADERS).forEach((header) => {
        if (fileHeaders.indexOf(header) === -1) {
          headerErrors.push(header);
        }
      });

      if (headerErrors.length) {
        setValidatedFileData({
          updatedFileData: null,
          errors: null,
          headerErrors,
        });
      } else {
        const missingFields = [];
        const firstRow = fileData[0];
        const firstRowError = Object.values(
          FileEnums.REQUIRED_FILE_HEADERS
        ).some((header) => {
          return !firstRow[header];
        });

        Object.values(FileEnums.REQUIRED_FILE_HEADERS).forEach((header) => {
          if (!firstRow[header]) {
            missingFields.push(header);
          }
        });

        if (firstRowError) {
          errors[0] = {
            requiredFields:
              missingFields
                .map((missingField) => `'${missingField}'`)
                .join(",") +
              " " +
              (missingFields.length === 1
                ? formatMessage(messages.requiredField)
                : formatMessage(messages.requiredFields)),
          };
        }

        let Section,
          Questions,
          Weighting,
          Comments,
          LinkedRecords,
          Score1,
          Score_1,
          Score0,
          Score0_1,
          Score0_2;

        const updatedFileData = [];

        fileData.forEach((row, index) => {
          Section = row[FileEnums.FILE_HEADERS_BYID.Section]
            ? row[FileEnums.FILE_HEADERS_BYID.Section]
            : Section;
          Questions = row[FileEnums.FILE_HEADERS_BYID.Questions]
            ? row[FileEnums.FILE_HEADERS_BYID.Questions]
            : Questions;
          Weighting = row[FileEnums.FILE_HEADERS_BYID.Weighting]
            ? row[FileEnums.FILE_HEADERS_BYID.Weighting]
            : Weighting;
          Comments = row[FileEnums.FILE_HEADERS_BYID.Comments]
            ? row[FileEnums.FILE_HEADERS_BYID.Comments]
            : Comments;
          LinkedRecords = row[FileEnums.FILE_HEADERS_BYID.LinkedRecords]
            ? row[FileEnums.FILE_HEADERS_BYID.LinkedRecords]
            : LinkedRecords;
          Score1 = row[FileEnums.FILE_HEADERS_BYID.Score1]
            ? row[FileEnums.FILE_HEADERS_BYID.Score1]
            : Score1;
          Score_1 = row[[FileEnums.FILE_HEADERS_BYID.Score_1]]
            ? row[[FileEnums.FILE_HEADERS_BYID.Score_1]]
            : Score_1;
          Score0 = row[FileEnums.FILE_HEADERS_BYID.Score0]
            ? row[FileEnums.FILE_HEADERS_BYID.Score0]
            : Score0;
          Score0_1 = row[FileEnums.FILE_HEADERS_BYID.Score0_1]
            ? row[FileEnums.FILE_HEADERS_BYID.Score0_1]
            : Score0_1;
          Score0_2 = row[FileEnums.FILE_HEADERS_BYID.Score0_2]
            ? row[FileEnums.FILE_HEADERS_BYID.Score0_2]
            : Score0_2;

          const updatedRow = {
            [FileEnums.FILE_HEADERS_BYID.Section]: Section,
            [FileEnums.FILE_HEADERS_BYID.Questions]: Questions,
            [FileEnums.FILE_HEADERS_BYID.Weighting]: Weighting,
            [FileEnums.FILE_HEADERS_BYID.Comments]: Comments,
            [FileEnums.FILE_HEADERS_BYID.LinkedRecords]: LinkedRecords,
            [FileEnums.FILE_HEADERS_BYID.Score1]: Score1,
            [FileEnums.FILE_HEADERS_BYID.Score_1]: Score_1,
            [FileEnums.FILE_HEADERS_BYID.Score0]: Score0,
            [FileEnums.FILE_HEADERS_BYID.Score0_1]: Score0_1,
            [FileEnums.FILE_HEADERS_BYID.Score0_2]: Score0_2,
          };
          updatedFileData.push(updatedRow);

          if (
            updatedRow[FileEnums.FILE_HEADERS_BYID.Weighting] &&
            isNaN(updatedRow[FileEnums.FILE_HEADERS_BYID.Weighting])
          ) {
            errors[index] = {
              ...errors[index],
              weightingError: formatMessage(messages.weightingError),
            };
          }

          if (
            updatedRow[FileEnums.FILE_HEADERS_BYID.Comments] &&
            !FileEnums.COMMENTS_VALUES[
              (
                updatedRow[FileEnums.FILE_HEADERS_BYID.Comments] + ""
              ).toLowerCase()
            ]
          ) {
            errors[index] = {
              ...errors[index],
              commentsError: formatMessage(messages.commentsError),
            };
          }

          if (
            updatedRow[FileEnums.FILE_HEADERS_BYID.LinkedRecords] &&
            !FileEnums.LINKED_RECORDS_VALUES[
              (
                updatedRow[FileEnums.FILE_HEADERS_BYID.LinkedRecords] + ""
              ).toLowerCase()
            ]
          ) {
            errors[index] = {
              ...errors[index],
              linkedRecordsError: formatMessage(messages.linkedRecordsError),
            };
          }
        });
        setValidatedFileData({ updatedFileData, errors, headerErrors });
      }
    }

    return errors;
  };

  useEffect(() => {
    validate();
  }, []);

  useEffect(() => {
    if (
      !validatedFileDataErrors.length &&
      !validatedFileHeaderErrors.length &&
      !stepThreeValid &&
      fileData.length
    )
      setStepThreeValid(true);
    if (
      (validatedFileDataErrors.length ||
        validatedFileHeaderErrors.length ||
        !fileData.length) &&
      stepThreeValid
    )
      setStepThreeValid(false);
  }, [validatedFileHeaderErrors, validatedFileDataErrors]);

  return (
    <span>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {formatMessage(messages.review)}
      </Typography>
      {fileDataError ? (
        <div className={classes.section}>
          <Alert severity="error">{formatMessage(messages.fileError)}</Alert>
        </div>
      ) : (
        <div className={classes.section}>
          {stepThreeValid && (
            <Alert severity="success">
              {formatMessage(messages.correctDocument)}
            </Alert>
          )}
          <span>
            {!stepThreeValid && (
              <Alert severity="error">
                {formatMessage(messages.errorDocument)}
              </Alert>
            )}
            <TableContainer component={Paper} className={classes.tableHeight}>
              <Table
                className={
                  validatedFileHeaderErrors.length ? "" : `${classes.table}`
                }
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th} />
                    {validatedFileHeaderErrors.length || !fileData.length ? (
                      <TableCell className={classes.th}>
                        {formatMessage(messages.error)}
                      </TableCell>
                    ) : (
                      <React.Fragment>
                        {fileHeaders.map((header, index) => (
                          <TableCell
                            className={classes.th}
                            style={{ width: index === 1 ? "300px" : "auto" }}
                            key={`header_${header}${index}`}
                          >
                            {header}
                          </TableCell>
                        ))}
                        <TableCell
                          className={classes.th}
                          style={{ width: "300px" }}
                        >
                          {formatMessage(messages.feedback)}
                        </TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {validatedFileHeaderErrors.length ? (
                    <TableRow>
                      <TableCell className={classes.textDanger}>
                        {formatMessage(messages.headersError)}
                        {validatedFileHeaderErrors.map((header, index) => (
                          <span key={`${header}${index}error`}>{`${header}${
                            index < validatedFileHeaderErrors.length - 1
                              ? ", "
                              : ""
                          }`}</span>
                        ))}
                      </TableCell>
                    </TableRow>
                  ) : !fileData.length ? (
                    <TableRow>
                      <TableCell className={classes.textDanger}>
                        {formatMessage(messages.noData)}
                      </TableCell>
                    </TableRow>
                  ) : (
                    validatedFileData.map((row, index) => {
                      return (
                        <TableRow key={`Row_${index}`}>
                          <TableCell>
                            {validatedFileDataErrors[index] ? (
                              <div className={classes.textDanger}>
                                <div
                                  style={{
                                    color: "orange",
                                  }}
                                >
                                  <Tooltip
                                    title={Object.keys(
                                      validatedFileDataErrors[index]
                                    ).map((el) => (
                                      <div
                                        key={`Erros_${validatedFileDataErrors[index][el]}`}
                                      >
                                        {validatedFileDataErrors[index][el]}
                                      </div>
                                    ))}
                                    /* title={formatMessage(messages.formErrors)} */
                                  >
                                    <WarningIcon />
                                  </Tooltip>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "green",
                                }}
                              >
                                <Tooltip
                                  title={formatMessage(messages.correctData)}
                                >
                                  <CheckIcon />
                                </Tooltip>
                              </div>
                            )}
                          </TableCell>
                          {Object.values(FileEnums.FILE_HEADERS_BYID).map(
                            (header, colIndex) => {
                              return (
                                <TableCell
                                  style={{
                                    width: colIndex === 1 ? "300px" : "auto",
                                  }}
                                  className={`${
                                    validatedFileDataErrors[index]
                                      ? classes.textDanger
                                      : classes.textSuccess
                                  }`}
                                  key={`Cell_${colIndex}`}
                                >
                                  {row[header]}
                                </TableCell>
                              );
                            }
                          )}
                          <TableCell style={{ width: "300px" }}>
                            {validatedFileDataErrors[index] ? (
                              <div className={classes.textDanger}>
                                {Object.keys(
                                  validatedFileDataErrors[index]
                                ).map((el) => (
                                  <div
                                    key={`Erros_${validatedFileDataErrors[index][el]}`}
                                  >
                                    {validatedFileDataErrors[index][el]}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <span className={classes.textSuccess}>
                                {formatMessage(messages.correctData)}
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </span>
        </div>
      )}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    fileData: getRawFileData(state),
    fileDataError: getRawFileDataError(state),
    fileHeaders: getFileHeaders(state),
    validatedFileData: getValidatedFileData(state),
    validatedFileDataErrors: getValidatedFileDataErrors(state),
    validatedFileHeaderErrors: getValidatedFileHeaderErrors(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setValidatedFileData: (data) => dispatch(setValidatedFileDataSuccess(data)),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StepThree)
);
