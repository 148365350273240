import translations from "../i18n/locales";
import { createIntl, createIntlCache } from "react-intl";

export default class Utils {
  static retrieveIntl() {
    const lang = "en";
    const messages = translations[lang];
    const intl = createIntl(
      {
        locale: lang,
        messages,
      },
      createIntlCache()
    );
    return intl;
  }
}
