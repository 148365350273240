export const GET_MODULES_REQUEST = "GET_MODULES_REQUEST";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_FAILURE = "GET_MODULES_FAILURE";

export const GET_MODULE_REQUEST = "GET_MODULE_REQUEST";
export const GET_MODULE_SUCCESS = "GET_MODULE_SUCCESS";
export const GET_MODULE_FAILURE = "GET_MODULE_FAILURE";

export const GET_FORM_REQUEST = "GET_FORM_REQUEST";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAILURE = "GET_FORM_FAILURE";

export const GET_LOCALES_REQUEST = "GET_LOCALES_REQUEST";
export const GET_LOCALES_SUCCESS = "GET_LOCALES_SUCCESS";
export const GET_LOCALES_FAILURE = "GET_LOCALES_FAILURE";

export const SET_INSPECTION_FORM_DATA = "SET_INSPECTION_FORM_DATA";
export const SET_INSPECTION_FORM_ERRORS = "SET_INSPECTION_FORM_ERRORS";
